import axios from 'axios'
import _ from 'lodash'
import Breadcrumb from '@/components/Breadcrumb.vue'
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor'

import CKEditor from '@ckeditor/ckeditor5-vue2'

export const utils = {
    components: { Breadcrumb, ckeditor: CKEditor.component, ClassicEditor },
    data () {
        return {
            context: null,
            anexos_actualizados: null,
            menus: null,
            baseUrl: process.env.VUE_APP_BASEURL,
            editor: ClassicEditor,
            editorConfig: {
                toolbar: ['heading', '|', 'bold', 'italic', '|', 'fontBackgroundColor', 'fontColor', 'fontFamily', 'fontSize', '|', 'bulletedList', 'numberedList', '|', 'insertTable', '|', '|', 'undo', 'redo', '|', 'link', '|', 'alignment', '|', 'HtmlEmbed', '|', 'imageUpload'],
                table: {
                    toolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'],
                    contentToolbar: [
                        'tableColumn', 'tableRow', 'mergeTableCells',
                        'tableProperties', 'tableCellProperties'
                    ],
                },
                link: {
                    decorators: {
                        openInNewTab: {
                            mode: 'manual',
                            label: 'Abrir en otra pesataña',
                            attributes: {
                                target: '_blank',
                                rel: 'noopener noreferrer'
                            }
                        }
                    }
                },
                language: 'es',
                image: {
                    toolbar: [
                    ]
                },
            }
        }
    },
    created: function () { },
    mounted () {
    },
    methods: {
        onCkEditorReady (editor) {
            editor.ui.view.editable.element.parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.view.editable.element
            )
        },
        modify_html_embed_to_iframe (url) {
            if (!url) { return url }
            let endpoint = url
            endpoint = endpoint.replace('oembed', 'iframe style="height:400px;width:800px"')
            endpoint = endpoint.replace('url=', 'src=')
            endpoint = endpoint.replace('watch?v=', 'embed/')
            endpoint = endpoint.replace('oembed', 'iframe')
            return endpoint
        },
        sortByDate (list) {
            list = list.sort(function (a, b) {
                var fechaPublicacion = b['dc.date.available'][0].slice(0, 4) - a['dc.date.available'][0].slice(0, 4)
                var ultimaModificacion = b.lastModified.slice(0, 4) - a.lastModified.slice(0, 4)
                return fechaPublicacion || ultimaModificacion
            })
        },
        getAuxiliares () {
            const url = '/api/paginas/?nombre=auxiliares'
            axios.get(url).then(res => {
                if (res.data && res.data[0]) { this.auxiliares = res.data[0].datos.context }
            })
        },
        makeid (length) {
            var result = ''
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            var charactersLength = characters.length
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength))
            }
            return result
        },
        sacarAcentos (s) {
            return s.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        },
        pasarSlug (s) {
            return this.sacarAcentos(this.trim(s)).replaceAll(';', '-').replaceAll(',', '-').replaceAll(' ', '-').replaceAll('(', '').replaceAll(')', '').replaceAll('?', '').replaceAll('+', '')
        },
        checkRegiones (x) {
            console.log('regiones', x)
            if (x === 'regiones') {
                return 'regiones/Regiones'
            } else {
                return x
            }
        },
        trim (s) {
            return (s || '').replace(/^\s+|\s+$/g, '')
        },
        getFileType (name) {
            if (name.split('.')[1].toLowerCase() === 'pdf') { return ' PDF' }
            if (name.split('.')[1].toLowerCase() === 'doc') { return ' WORD' }
            if (name.split('.')[1].toLowerCase() === 'jpg') { return ' JPG' }
            if (name.split('.')[1].toLowerCase() === 'csv') { return ' CSV' }
            if (name.split('.')[1].toLowerCase() === 'xls') { return ' EXCEL' }
            if (name.split('.')[1].toLowerCase() === 'xlsx') { return ' EXCEL' }
            if (name.split('.')[1].toLowerCase() === 'do') { return ' STATA' }
            if (name.split('.')[1].toLowerCase() === 'dta') { return ' DTA' }
            return name.split('.')[1].toUpperCase()
        },

        getFileTypeIcon (name) {
            if (name.split('.')[1].toLowerCase() === 'pdf') { return ['fas', 'file-pdf'] }
            if (name.split('.')[1].toLowerCase() === 'doc') { return ['fas', 'file-word'] }
            if (name.split('.')[1].toLowerCase() === 'jpg') { return ['fas', 'file-image'] }
            if (name.split('.')[1].toLowerCase() === 'csv') { return ['fas', 'file-csv'] }
            if (name.split('.')[1].toLowerCase() === 'xls') { return ['fas', 'file-excel'] }
            if (name.split('.')[1].toLowerCase() === 'xlsx') { return ['fas', 'file-excel'] }
            return ['fas', 'file']
        },
        load (modulo, collection) {
            var url = `/api/paginas/?nombre=${modulo}`
            if (collection) {
                url = `/api/paginas/?nombre=${modulo}&collection=${collection}`
            }
            axios.get(url).then(res => {
                if (res.data && res.data[0]) {
                    this.context = res.data[0].datos.context
                    this.anexos_actualizados = res.data[0].anexos_actualizados
                }
            })
        },

        getUrlGaleria (galeria) {
            return this.baseUrl + '/api/galeria/' + galeria.id + '/archivo'
        },

        get_menu (nombre) {
            axios.get('/api/menus?nombre=' + nombre).then(res => { this.menus = _.sortBy(res.data[0].menu, 'order') })
        },

        bajar (contenedor, columna, index) {
            var copia = JSON.parse(JSON.stringify(contenedor[columna]))
            var original = JSON.parse(JSON.stringify(copia[index]))
            var debajo = JSON.parse(JSON.stringify(copia[index + 1]))
            copia[index + 1] = original
            copia[index] = debajo
            contenedor[columna] = copia
        },
        subir (contenedor, columna, index) {
            var copia = JSON.parse(JSON.stringify(contenedor[columna]))
            var original = JSON.parse(JSON.stringify(copia[index]))
            var arriba = JSON.parse(JSON.stringify(copia[index - 1]))
            copia[index - 1] = original
            copia[index] = arriba
            contenedor[columna] = copia
        },
        quitar (contenedor, columna, index) {
            this.$swal({
                title: 'Vas a eliminar este elemento, ¿estás seguro?',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    index = index + 1
                    var copia = JSON.parse(JSON.stringify(contenedor[columna]))
                    contenedor[columna] = [].concat(copia.slice(0, index - 1), copia.slice(index, copia.length))
                }
            })
        },
        agregar (contenedor, columna, index, value) {
            value = value || ''
            index = index + 1
            var copia = JSON.parse(JSON.stringify(contenedor[columna]))
            contenedor[columna] = [].concat(copia.slice(0, index), [value], copia.slice(index, copia.length))
        },

        filtrados (resultados, buscador) {
            if (!buscador) { return resultados }
            return resultados.filter(e => { return e.title && e.title.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').search(buscador.toLocaleLowerCase()) >= 0 })
        },

        setOptions (metadato, options) {
            // se utiliza en el gestor
            if (typeof (metadato) === 'string') {
                for (const index in metadato.split(';')) {
                    if (options.indexOf(metadato.split(';')[index]) === -1) {
                        options.push(metadato.split(';')[index])
                    }
                }
            } else {
                for (const index in metadato) {
                    if (options.indexOf(metadato[index]) === -1) {
                        options.push(metadato[index])
                    }
                }
            }
        }

    }
}
